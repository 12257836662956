import React,{useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link,useNavigate } from 'react-router-dom'

const IndustriesRetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Retail",  // Replace this with your actual data
            },
          });
    }

  return (
    <>
        <Header activelink='industries'/>

        <main style={{backgroundColor:'#fff'}}>
            {/* <!--Page Banner--> */}
            <section className="hero-bannercareer">
                <div className="container py-lg-5">
                    <div className="col-xxl-12 mx-auto">
                        <div className="row py-5">
                            <div className="col-xxl-8 col-lg-9 fade-in-element left">
                                <p className="small__by_text"> <Link to="/industries-finance"> Industries /</Link> <span><Link to="/industries-retail">Retail</Link></span></p>
                                <h1 className="Careers-herobannertext">Redefining Retail Conversations</h1>
                                <p className="lead-careertext">Dive into a transformative retail experience with Enterprise AI Copilots that understand, engage, and anticipate your customer needs</p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                    <span onClick={handleDemoClick} className="btn btn-careerseeopen">Request a demo</span>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <img src="/img/industries-retailer/object.png" alt="" className="circle-elipsse-retailer"/>
            </section>

            {/* <!--Para--> */}
            <section className="bg-gray-light">
                <div className="px-lg-5 pt-lg-5 mb-lg-5 pt-sm-2 px-sm-2 pb-md-5 pb-3 container-fluid">
                    <div className="col-lg-11 mx-auto">                       
                        <div className="">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                                The retail industry is facing unprecedented challenges, from changing consumer preferences to intense competition and rising costs. To stay ahead, retailers need innovative solutions that can help them streamline operations, enhance customer experiences, and boost profits. That's where our generative AI chatbot and other AI solutions come in.
                            </p>
                        </div>                        
                    </div>
                </div>
            </section>

            {/* <!--AI Solutions for Retail--> */}
            <section>
                <div className=" container-fluid px-lg-5 pt-md-2">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin mt-md-5 mt-4 mb-sm-2 pb-sm-1">AI Solutions for Retail</h2>                      
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid py-lg-5 mt-md-0 fade-in-element">
                    <div className="row">
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Personalization</h3>
                                    <p className="industries-retailerpara">
                                        Analyze consumer data to create personalized customer experiences, such as tailored product recommendations, promotions, and content.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer2.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Inventory Management</h3>
                                    <p className="industries-paratext">
                                        Optimize inventory management by forecasting demand and streamlining supply chain logistics, helping retailers reduce costs by minimizing stockouts and overstocking.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer3.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Customer Service</h3>
                                    <p className="industries-paratext">
                                        Generative AI-powered chatbots can provide 24/7 customer service, answering common questions and resolving issues quickly, improving the customer experience and reducing wait times.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer4.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Marketing</h3>
                                    <p className="industries-retailerpara">
                                        Generative AI can create targeted marketing campaigns based on consumer data, helping retailers improve their marketing ROI by reaching the right consumers with the right message.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer5.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Fraud Detection</h3>
                                    <p className="industries-retailerpara">
                                        Generative AI algorithms can analyze transaction data to detect fraudulent activity and prevent chargebacks, helping retailers reduce losses due to fraud and protect customer data.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer6.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Visual Search</h3>
                                    <p className="industries-paratext">
                                        Generative AI can enable visual search, allowing customers to upload images of products they are interested in and find similar products in the retailer's inventory, improving the customer experience by providing an easy and convenient way for buyers to find what they want.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Driving Tangible Growth in the Retail Space--> */}
            <section className="epb-lg-5 mb-lg-5 pt-lg-5">
                <div className="container-fluid px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin">Driving Tangible Growth in the Retail Space</h2>                     
                    </div>
                </div>
                <div className="container-fluid mt-md-5 mt-2 px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <div className="row">
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                <h5>Increased Sales Revenue</h5>
                                <p>Harness AI to boost average order values and repeat purchases, potentially elevating sales revenue by up to 20%.</p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Inventory Cost Savings</h5>
                                    <p>Streamline inventory management with AI insights, leading to a potential reduction in holding costs by 15%.</p>       
                                </div> 
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Uplift in Customer Retention</h5>
                                    <p>Enhanced customer support and personalized experiences can elevate retention rates by 10%, fostering long-term growth.</p>       
                                </div>
                            </div>
                    </div>  
                    </div>
                </div>
            </section>

            {/* <!--Trust Neuralogix--> */}
            <section className="industries-impactai">
                <div className="container">
                    <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                        <div className="card-carouselindutries fade-in-element right">
                            <h3 className="pt-lg-5 mb-lg-4">Trust Neuralogix  for Secure and Scalable Solutions</h3>  
                            <p><b className="text-white">Secure & Scalable Framework:</b> Data security is paramount in today's digital age. Neuralogix's commitment to hosting purpose-tuned, Large Language Models in private clouds ensures that our clients’ sensitive data remains confidential</p>
                            <p>
                                <b className="text-white">Customized Generative AI Solutions:</b> We don't just offer a one-size-fits-all AI solution. Our strength lies in recognizing the unique needs of different industries and customizing our AI platforms to specifically cater to those needs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>           
        </main>
        
        {/* <!-- Footer match --> */}
        <section className="hero-bannercareer border-bottomfooter fade-in-element">
            <div className="container py-4">
                <div className="col-lg-12 mx-auto">
                    <div className="row py-md-5 py-2">
                        <div className="col-lg-12">
                            <h1 className="chat-llm">Optimize Your Retail Experience</h1>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                            <span onClick={handleDemoClick} className="btn btn-consulation">Book a Consultation</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>

        <Footer />
    </>
  )
}

export default IndustriesRetails
