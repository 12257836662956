import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SolutionsAIChatBots from './pages/SolutionsAIChatBots';
import Careers from './pages/Careers';
import CareersOpening from './pages/CareersOpening';
import Company from './pages/Company';
import IndustriesFinance from './pages/IndustriesFinance';
import Insight from './pages/Insight';
import IndustriesManufacturing from './pages/IndustriesManufacturing';
import SolutionsEnterpriseAICopilots from './pages/SolutionsEnterpriseAICopilots';
import IndustriesRetails from './pages/IndustriesRetails';
import IndustriesHealthCare from './pages/IndustriesHealthCare';
import Contact from './pages/Contact';
import Blog1 from './pages/Blog1';
import Blog2 from './pages/Blog2';
import Blog3 from './pages/Blog3';
import Blog4 from './pages/Blog4';
import Blog5 from './pages/Blog5';
import Blog6 from './pages/Blog6';
import PrivacyPolicy from './pages/PrivacyPolicy';
import 'sweetalert2/dist/sweetalert2.css';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/solutions-ai-chatbots" element={<SolutionsAIChatBots />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers-opening" element={<CareersOpening />} />
        <Route path="/careers-opening?jobid=id" element={<CareersOpening />} />
        <Route path="/company" element={<Company />} />
        <Route path="industries-finance" element={<IndustriesFinance />} />
        <Route path="/insight" element={<Insight />} />
        <Route path="/industries-manufacturing" element={<IndustriesManufacturing />} />
        <Route path="/solutions-enterprise-ai-copilots" element={<SolutionsEnterpriseAICopilots />} />
        <Route path="/industries-retail" element={<IndustriesRetails />} />
        <Route path="/industries-healthcare" element={<IndustriesHealthCare />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog1" element={<Blog1 />} />
        <Route path="/blog2" element={<Blog2 />} />
        <Route path="/blog3" element={<Blog3 />} />
        <Route path="/blog4" element={<Blog4 />} />
        <Route path="/blog5" element={<Blog5 />} />
        <Route path="/blog6" element={<Blog6 />} />  
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter> 
    </>  
  );
}

export default App;
