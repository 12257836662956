import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function Header({ activelink }) {

    useEffect(() => {
        const elements = document.querySelectorAll('.fade-in-element');

        const handleScroll = () => {
            elements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;


                if (elementTop < windowHeight * 0.8) {
                    if (element.classList.contains('left')) {
                        element.classList.add('fade-in-left');
                    } else if (element.classList.contains('right')) {
                        element.classList.add('fade-in-right');
                    } else if (element.classList.contains('bottom')) {
                        element.classList.add('fade-in-bottom');
                    } else {
                        element.classList.add('visible');
                    }
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Initial check in case elements are already visible on load
        handleScroll();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigate = useNavigate()
    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Generic",  // Replace this with your actual data
            },
          });
    }


    return (
        <>
            <header className="bg-black fixed-top">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg" aria-label="Eleventh navbar example">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/">
                                <img src="/img/logo.png" alt="logo" className="logo" />
                            </Link>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarsExample09"
                                aria-controls="navbarsExample09"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="fa-solid fa-chart-bar text-white"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarsExample09">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item dropdown">
                                        <Link
                                            className={`${activelink === 'solutions' ? 'nav-link dropdown-toggle text-white px-4 active' : 'nav-link dropdown-toggle text-white px-4'}`}
                                            to="#"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >Solutions</Link>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/solutions-ai-chatbots" className="dropdown-item">AI Chatbots</Link>
                                            </li>
                                            <li>
                                                <Link to="/solutions-enterprise-ai-copilots" className="dropdown-item">Enterprise AI Copilots</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link
                                            className={`${activelink === 'industries' ? 'nav-link dropdown-toggle text-white px-4 active' : 'nav-link dropdown-toggle text-white px-4'}`}
                                            to="#"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >Industries</Link>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/industries-finance" className="dropdown-item">Finance</Link>
                                            </li>
                                            <li>
                                                <Link to="/industries-retail" className="dropdown-item">Retail</Link>
                                            </li>
                                            <li>
                                                <Link to="/industries-healthcare" className="dropdown-item">Healthcare</Link>
                                            </li>
                                            <li>
                                                <Link to="/industries-manufacturing" className="dropdown-item">Manufacturing</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/company" className={`${activelink === 'company' ? 'nav-link px-4 text-white active' : 'nav-link px-4 text-white'}`}>Company</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/insight" className={`${activelink === 'insights' ? 'nav-link px-4 text-white active' : 'nav-link px-4 text-white'}`}>Insights</Link>
                                    </li>
                                </ul>
                                <div className="d-lg-flex col-lg-3 justify-content-lg-end">
                                    <ul className="navbar-nav mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <Link to="/careers" className={`${activelink === 'careers' ? 'nav-link px-4 text-white active' : 'nav-link px-4 text-white'}`}>Careers</Link>
                                        </li>
                                        <li className="nav-item">
                                            <span onClick={()=>handleDemoClick()}
                                            style={{cursor:"pointer"}}
                                             className={`${activelink === 'contact' ? 'nav-link px-4 text-white active' : 'nav-link px-4 text-white'}`}>Contact</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
};