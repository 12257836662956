import React, { useEffect } from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link  } from 'react-router-dom';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div>
            {/* Include the Header component */}
            <Header />

            {/* <!--Home Banner--> */}
            <section className="homebanner">
                <div className="container-fluid">
                    <div className="container px-lg-4 py-lg-5">
                        <div className="row flex-lg-row align-items-center py-2">
                            <div className="fade-in-element fade-in-bottom">
                                <h1 className="display-1 fw-bold lh-1 mb-4 mt-4 text-white text-center">Unlock the Full Potential of AI for Your Business</h1>
                                <p className="mb-5 colorbgcontent text-center">
                                    Automate complex processes, generate new revenue streams and enhance customer experience
                                    <br />
                                    with our Enterprise AI
                                </p>
                                <div className="d-grid gap-2 d-md-flex justify-content-center">
                                    <Link to="/contact" className="btn btn-lg me-md-2 requestdemo new-homepagebtn">Request a demo</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Intro Section--> */}
            <section className="homeintro">
                <div className="container-fluid ps-md-5 ps-1">
                    <div className="row">
                        <div className="col-lg-7 fade-in-element left">
                            <div className="leftborder mt-lg-5 mb-lg-3">
                                <h2 className="display-4 fw-bold text-borderand mb-lg-3">Neuralogix introduces Generative AI solutions for Enterprises</h2>
                                <p className="fs-4 py-2 ps5rem">Discover how to move past the buzz and unlock genuine business benefits from Generative AI.</p>
                            </div>
                            <div className="linkcontainer">
                                <Link to="/solutions-enterprise-ai-copilots" className="text-decoration-none readmore">Read more</Link>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center px-lg-0 fade-in-element right">
                            <img src="/img/home/neuralogix-chat-v2.svg" alt="Intro" className="introimg" />
                        </div>
                    </div>
                    <div className="row"></div>
                </div>
            </section>

            {/* <!--Why Generative AI--> */}
            <section className="homewhy fade-in-element bottom">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 bg-black box">
                            <img src="/img/home/icon1.svg" alt="Why" className="icon" />
                            <h2 className="fs-3 fw-bold lh-1 mt-3 mb-3 text-white">Level-up your Customer Interactions</h2>
                            <p className="text-white">
                                Revolutionizing your marketing, sales, and customer support with Generative AI powered chatbots that can tailor recommendations, optimize customer journeys, and even provide real-time assistance.
                            </p>
                            <Link to="/solutions-ai-chatbots" className="text-decoration-none readmore text-white">Read more</Link>
                        </div>
                        <div className="col-lg-6 bg-grey box">
                            <img src="/img/home/icon2.svg" alt="Why" className="icon" />
                            <h2 className="fs-3 fw-bold lh-1 mt-3 mb-3">Total Enterprise Reinvention</h2>
                            <p>
                                Unleash the power of your internal proprietary data and insights with our custom-tuned LLMs, automating tasks across functions and empowering your teams with intelligent co-pilots for unparalleled efficiency and
                                competitiveness.
                            </p>
                            <Link to="/solutions-enterprise-ai-copilots" className="text-decoration-none readmore">Read more</Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Disover Use Cases--> */}
            <section className=" py-lg-5 fade-in-element bottom">
                <div className="container">
                    <div className="row py-md-4 py-2">
                        <div className="col-lg-7 py-lg-5 py-2">
                            <h2 className="display-4 fw-bold mb-4">Discover Use Cases For Your Industry</h2>
                            <p className="fs-4 mb-4 fw-normal lh-2">Neuralogix can help you solve your most pressing challenges and make better business decisions.</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid g-0">
                    <div className="usecase">
                        <div id="myCarousel2" className="carousel">
                            <div className="carousel-inner multitem">
                                <div className="carousel-item multitem active">
                                    <div className="card">
                                        <Link to="/industries-finance" className="zoom-effect-hover">
                                            <img src="/img/home/financial-service.png" alt="carousel" />
                                        </Link>
                                        <div className="cardcontent">
                                            <Link to="/industries-finance">
                                                <h2 className="fs-3 fw-bold py-2 text-white">Financial Services</h2>
                                                <p className="fs-5 text-white">Detect fraud earlier, mitigate risk, and drive customer loyalty.</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item multitem">
                                    <div className="card">
                                        <Link to="/industries-retail" className="zoom-effect-hover">
                                            <img src="/img/home/retail-service.png" alt="carousel" />
                                        </Link>
                                        <div className="cardcontent">
                                            <Link to="/industries-retail">
                                                <h2 className="fs-3 fw-bold py-2 text-white">Retail</h2>
                                                <p className="fs-5 text-white">Forecast demand, optimize staffing, and improve customer experiences.</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item multitem">
                                    <div className="card">
                                        <Link to="/industries-healthcare" className="zoom-effect-hover">
                                            <img src="/img/home/healthcare-service.png" alt="carousel" />
                                        </Link>
                                        <div className="cardcontent">
                                            <Link to="/industries-healthcare">
                                                <h2 className="fs-3 fw-bold py-2 text-white">Healthcare</h2>
                                                <p className="fs-5 text-white">Optimize patient care, accelerate research and forecast operational needs.</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item multitem">
                                    <div className="card">
                                        <Link to="/industries-manufacturing" className="zoom-effect-hover">
                                            <img src="/img/home/manufacturing-service.png" alt="carousel" />
                                        </Link>
                                        <div className="cardcontent">
                                            <Link to="/industries-manufacturing">
                                                <h2 className="fs-3 fw-bold py-2 text-white">Manufacturing</h2>
                                                <p className="fs-5 text-white">Optimize patient care, accelerate research and forecast operational needs.</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--About Us--> */}
            <section className="fade-in-element bottom">
                <div className="container col-xxl-10 px-lg-4 py-lg-5 py-3">
                    <div className="row flex-lg-row g-5 py-lg-5">
                        <div className="col-lg-10 mx-auto">
                            <h3 className="abouthometextheading">About Us</h3>
                            <p className="lead fw-normal paragaraph">
                                At Neuralogix, we re on a mission to revolutionize the way businesses use artificial intelligence. Our team of experienced technology professionals and cutting-edge AI researchers are dedicated to developing
                                innovative solutions that deliver practical, real-world results.
                            </p>
                            <p className="lead fw-normal paragaraph">We re committed to helping our clients streamline processes, enhance decision-making, and unlock new opportunities for growth.</p>
                            <div className="linkcontainer homewhy mb-lg-4">
                                <Link to="/company" className="text-decoration-none readmore">Read more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Masonry--> */}
            <section className="masonry bg-grey">
                <div className="container py-lg-5 py-3">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-sm-6 col-lg-6 mb-lg-4 fade-in-element left">
                                    <div className="card bg-grey">
                                        <div className="card-body px-0">
                                            <h2 className="masonary-heading px-lg-5 mt-lg-5">Stay ahead of the AI curve</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ps-lg-0 fade-in-element right">

                                    <Link to="/blog6">
                                        <div className="blog-contentceo">
                                            <span className="">
                                                <img src="/img/home/home-log-icon.svg" alt="" />
                                            </span>
                                            <h5>6 Generative AI Business Myths that will make you Rethink Everything</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 mb-lg-4 px-0">
                                <div className="row mt-2">
                                    <div className="col-lg-6 fade-in-element left">
                                        <Link to="/blog1">
                                            <div className="aceoguide">
                                                <h5 className="text-content-ceo">
                                                    A CEO's Guide
                                                    <br />
                                                    to Generative
                                                    <br />
                                                    AI
                                                </h5>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-6 mt-lg-0 mt-2 fade-in-element right">
                                        <div className="row">
                                            <div className="col-lg-6 ps-lg-0">
                                                <Link to="/blog2">
                                                    <div className="blog-contentceo heigtmin py-lg-4">
                                                        <span className="">
                                                            <img src="/img/home/home-blog-icon2.svg" alt="" />
                                                        </span>
                                                        <h5>Generative AI vs. Traditional Machine Learning: What's the Difference?</h5>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-lg-6 ps-lg-0 mt-lg-0 mt-sm-2">
                                                <Link to="/blog3">
                                                    <div className="blog-contentceo heigtmin py-lg-4">
                                                        <span className="">
                                                            <img src="/img/home/home-blog-icon3.svg" alt="" />
                                                        </span>
                                                        <h5 style={{ marginBottom: '2rem' }}>How to Implement Generative AI in your Enterprise</h5>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Footer match --> */}
            <section className="hero-bannercareer  border-bottomfooter">
                <div className="container pb-5">
                    <div className="col-lg-10 py-lg-5 mx-auto">
                        <div className="row pt-lg-5">
                            <div className="col-lg-12 fade-in-element left">
                                <h1 className="chat-llm">
                                    Learn how our Enterprise AI software can help your organization.
                                </h1>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                                    <Link to="/contact" className="btn btn-consulation">Request a demo</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Include the Footer component */}
            <Footer />
        </div>
    );
};

export default Home;
