import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-black px-lg-5">
        <footer className="row py-5 mx-3">
            <div className="col-md-5 mb-3">
                <div className="row">
                    <div className="col-md-12">
                        <Link to="/" className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
                            <img src="img/logo.png" alt="" className="logo"/>
                        </Link>
                        <ul className="list-unstyled d-flex justify-content-start gap-30px mt-5">
                            <li>                             
                                <Link to="#" className="social-icon">
                                    <img src="img/fb-logo.png" alt=""/>
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className="social-icon">
                                    <img src="img/linkedin-logo.png" alt=""/>
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className="social-icon">
                                    <img src="img/x-logo.png" alt=""/>
                                </Link>
                            </li>
                        </ul>
                        <p className="footer-copy mt-4 pt-2 align-items-start">
                            © 2023 Neuralogix.ai 
                            <small className="px-2">|</small> 
                            <small>
                                <Link to="/privacy-policy" className="text-white op-80">Privacy Policy</Link>
                            </small>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-7">
                <div className="row">
                    <div className="col-md-4 mb-3 text-white">
                        <h5>Solutions</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2">
                                <Link to="/solutions-ai-chatbots" className="nav-link p-0">AI Chatbots</Link>
                            </li>
                            <li className="nav-item mb-2">
                                <Link to="/solutions-enterprise-ai-copilots" className="nav-link p-0">Enterprise AI Copilots</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4 mb-3 text-white">
                        <h5>Industries</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2">
                                <Link to="/industries-finance" className="nav-link p-0">Finance</Link>
                            </li>
                            <li className="nav-item mb-2">
                                <Link to="/industries-retail" className="nav-link p-0">Retail</Link>
                            </li>
                            <li className="nav-item mb-2">
                                <Link to="/industries-healthcare" className="nav-link p-0">Healthcare</Link>
                            </li>
                            <li className="nav-item mb-2">
                                <Link to="/industries-manufacturing" className="nav-link p-0">Manufacturing</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4 mb-3 text-white">
                        <h5>Quick Links</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2">
                                <Link to="/company" className="nav-link p-0">Company</Link>
                            </li>
                            <li className="nav-item mb-2">
                                <Link to="/insight" className="nav-link p-0">Insights</Link>
                            </li>
                            <li className="nav-item mb-2">
                                <Link to="/careers" className="nav-link p-0">Careers</Link>
                            </li>
                            <li className="nav-item mb-2">
                                <Link to="/contact" className="nav-link p-0">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    </>
  );
};

export default Footer;
