import React, { useEffect } from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link, useNavigate } from 'react-router-dom'

const IndustriesFinance = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
const navigate = useNavigate()
const handleRequestDemoClick = () => {
    navigate('/contact', {
        state: {
          demoNeededFor: "Finance",  // Replace this with your actual data
        },
      });
}

    return (
        <>
            <Header activelink='industries' />

            <main style={{ backgroundColor: '#fff' }}>
                {/* <!--Page Banner--> */}
                <section className="hero-bannercareer">
                    <div className="container py-lg-5">
                        <div className="col-xxl-12 mx-auto">
                            <div className="row py-5">
                                <div className="col-xxl-8 col-lg-9 col-md-12 fade-in-element left">
                                    <p className="small__by_text"> <Link to="/industries-finance"> Industries / </Link> <span><Link to="/industries-finance">Finance</Link></span></p>
                                    <h1 className="Careers-herobannertext">Empowering Financial Intelligence</h1>
                                    <p className="lead-careertext">Elevate your financial services with generative AI, making interactions seamless and decisions data-driven, every step of the way.</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                        <span  onClick={handleRequestDemoClick}
                                        className="btn btn-careerseeopen">Request a demo</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/img/industries-retailer/financial/finacial-banner-short-image.svg" alt="" className="circle-elipsse-retailer" />
                </section>

                {/* <!--Para--> */}
                <section className="bg-gray-light pb-md-4">
                    <div className="pt-lg-5 mb-lg-5 pt-sm-2 px-sm-2  container-fluid  px-lg-5">
                        <div className="col-xxl-11 mx-auto">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                                The financial sector is navigating a rapidly evolving landscape, characterized by shifting regulations, evolving customer expectations, and the constant pursuit of efficiency and profitability. To thrive in this dynamic environment, financial institutions require innovative solutions that can streamline operations, improve customer experiences, and drive sustainable growth. This is where our state-of-the-art AI solutions come into play.
                            </p>
                        </div>
                    </div>
                </section>

                {/* <!--AI Solutions for Financial Sector--> */}
                <section>
                    <div className=" py-lg-5 my-lg-5 container-fluid fade-in-element">
                        <div className="">
                            <div className="container-fluid px-lg-5">
                                <div className="col-lg-11 mx-auto">
                                    <h2 className="career-headingwhyjoin mb-lg-5 mb-sm-2 pb-lg-4 pb-sm-1">AI Solutions for Financial Sector</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack">
                                        <div className="card-body px-lg-5 py-4">
                                            <img src="/img/industries-retailer/financial/icon-financial1.svg" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Risk Assessment</h3>
                                            <p className="industries-retailerpara">
                                                We provide a comprehensive suite of AI-driven solutions for the financial sector:
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                        <div className="card-body px-lg-5 px-sm-2 py-4">
                                            <img src="/img/industries-retailer/financial/icon-fnancial2.svg" alt="" className="my-3" />
                                            <h3 className="industries-textheading">Fraud Detection</h3>
                                            <p className="industries-paratext">
                                                Utilize machine learning algorithms and real-time monitoring to detect and prevent fraudulent activities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                        <div className="card-body px-lg-5 px-sm-2 py-4">
                                            <img src="/img/industries-retailer/financial/financial3.svg" alt="" className="my-3" />
                                            <h3 className="industries-textheading">Customer Insights</h3>
                                            <p className="industries-paratext">
                                                Leverage AI to gain deep insights into customer behavior and preferences, enabling personalized services and marketing strategies.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack">
                                        <div className="card-body px-lg-5 py-4">
                                            <img src="/img/industries-retailer/financial/icon-financial4.svg" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Data Security</h3>
                                            <p className="industries-retailerpara">
                                                Our AI solutions incorporate robust encryption and access controls to safeguard sensitive financial data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Financial Mastery with Measurable Results--> */}
                <section className="mt-lg-5 pb-lg-5 mb-lg-5 pt-lg-5">
                    <div className="container-fluid px-lg-5">
                        <div className="col-lg-11 mx-auto">
                            <h2 className="career-headingwhyjoin">Financial Mastery with Measurable Results</h2>
                        </div>
                    </div>
                    <div className="container-fluid mt-md-5 mt-2 px-lg-5">
                        <div className="col-lg-11 mx-auto">
                            <div className="row">
                                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible ">
                                        <h5>Enhanced Portfolio Returns</h5>
                                        <p>AI-driven market forecasts can potentially improve portfolio returns by up to 25%.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible">
                                        <h5>Reduced Compliance Violations</h5>
                                        <p>Automated compliance ensures fewer breaches, leading to potential savings of millions in fines.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible ">
                                        <h5>Elevated Client Acquisition</h5>
                                        <p>Personalized financial advice can increase new client acquisitions by up to 18%.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Trust Neuralogix--> */}
                <section className="industries-impactai">
                    <div className="container">
                        <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                            <div className="card-carouselindutries fade-in-element right">
                                <h3 className="pt-lg-5 mb-lg-4">Trust Neuralogix  for Secure and Scalable Solutions</h3>
                                <p><b className="text-white">Secure & Scalable Framework:</b> Data security is paramount in today's digital age. Neuralogix's commitment to hosting purpose-tuned, Large Language Models in private clouds ensures that our clients’ sensitive data remains confidential</p>
                                <p>
                                    <b className="text-white">Customized Generative AI Solutions:</b> We don't just offer a one-size-fits-all AI solution. Our strength lies in recognizing the unique needs of different industries and customizing our AI platforms to specifically cater to those needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <!-- Footer match --> */}
            <section className="hero-bannercareer border-bottomfooter fade-in-element">
                <div className="container py-4">
                    <div className="col-lg-12 mx-auto">
                        <div className="row py-md-5 py-2">
                            <div className="col-lg-12">
                                <h1 className="chat-llm">Revolutionize finance with AI-driven insights. Discover endless possibilities</h1>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                                <span  onClick={handleRequestDemoClick} className="btn btn-careerseeopen">Request a demo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default IndustriesFinance
