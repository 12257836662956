class GeneralUtils {

    static validationForName(value) {
        if (/^[a-zA-Z\s]*$/.test(value)) {
            return true;
        }

        return false;
    }

    static validationForEmail(value) {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            return true;
        }
        return false;
    }

    /// test space  and only number
    static validationForNumber(value) {
        if (/^[0-9]*$/.test(value)) {
            return true;
        }
        return false;
    }


}

export default GeneralUtils;