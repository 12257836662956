import { apiRequest } from "./apiRequest";

export const getJobList = async () => {

    const api = await apiRequest({
        url: "/jobMaster/active",
        method: "get",
    });
    return api.data;
};

export const getJobById = async (jobId) => {

    const api = await apiRequest({
        url: `/jobMaster/${jobId}`,
        method: "get",
    });
    return api.data;
};

export const applyForJob = async (jobData) => {
    const api = await apiRequest({
        url: "/jobApply",
        method: "post",
        body: jobData
    });
    return api.data;
};