import React, { useEffect } from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link, useNavigate } from 'react-router-dom'

const SolutionsEnterpriseAICopilots = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
const navigate = useNavigate()

    const handleRequestDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Enterprise AI Copilots",  // Replace this with your actual data
            },
          });
    }

    return (
        <>
            <Header activelink='solutions' />

            <main className="pb-lg-1 pb-sm-1">
                {/* <!--Page Banner--> */}
                <section className="hero-bannercareer">
                    <div className="container py-lg-5">
                        <div className="col-xxl-12 mx-auto">
                            <div className="row py-5">
                                <div className="col-xxl-9 col-lg-10 fade-in-element left">
                                    <p className="small__by_text"> <Link to="/solutions-ai-chatbots"> Solutions /</Link> <span><Link to="/solutions-enterprise-ai-copilots">Enterprise AI Copilots</Link></span></p>
                                    <h1 className="Careers-herobannertext">Enterprise AI Copilots</h1>
                                    <p className="lead-careertext">Unlock the power of generative AI to Streamline operations, enhance <br />decision-making, and drive unparalleled efficiency.</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                        <span 
                                        onClick={handleRequestDemoClick}
                                        className="btn btn-careerseeopen">Request a demo</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/img/solution/copilot.png" alt="" className="circle-elipsse" />
                </section>

                {/* <!--Para--> */}
                <section className="solution-section">
                    <div className="py-md-5 py-3 pt-sm-2 px-sm-2 container">
                        <div className="col-lg-12 mx-auto px-lg-4">
                            <div className="ai-chatsolution py-md-5 py-2 fade-in-element bottom">
                                <p>According to a recent report by McKinsey & Company, AI is expected to generate <span>$13 trillion in additional economic output</span> by 2030. Don't miss out on the AI revolution! Neuralogix Enterprise AI Copilots can help you get started on your AI journey and accelerate your digital transformation.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Business Functions--> */}
                <section>
                    <div className="container px-lg-4">
                        <div className="col-lg-12 mx-auto my-lg-5 pt-sm-2 px-sm-2 my-sm-2 ">
                            <div className="col-lg-10">
                                <h2 className="career-headingwhyjoin">Tailored digital assistants to drive efficiency, unlock insights, and automate tasks across  business Functions</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container px-lg-4">
                        <div className="col-lg-12 mx-auto px-lg-2">
                            <div className="row mt-md-5">
                                <div className="col-lg-7 ps-md-4 pt-md-4 pe-md-0 bg-gray-light border-top-raduis fade-in-element left">
                                    <img src="/img/solution/enterprise-search.svg" alt="" width="100%" />
                                </div>
                                <div className="col-lg-4 ms-auto">
                                    <div className="content-text-copilot fade-in-element right">
                                        <h3>Enterprise Knowledge </h3>
                                        <p>This is a sophisticated AI-driven platform designed to seamlessly integrate, analyze, and make accessible the vast body of knowledge within an enterprise. It's not just a search tool but a proactive assistant, ensuring that every employee, from a new hire to the CEO, has the collective wisdom of the organization at their fingertips.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row flex-row-reverse mt-md-5 pt-md-5">
                                <div className="col-lg-7 ps-md-4 pt-md-4 pe-md-0 bg-gray-light border-top-raduis fade-in-element right">
                                    <img src="/img/solution/enterprise-finance.svg" alt="" width="100%" />
                                </div>
                                <div className="col-lg-4 me-md-auto">
                                    <div className="content-text-copilot fade-in-element left">
                                        <h3>Finance</h3>
                                        <p>A financial analyst copilot could assist accountants and financial managers in analyzing large datasets, identifying trends, and generating forecasts. By automating routine tasks such as data entry and reconciliation, this copilot would free up time for more strategic decision-making and risk analysis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-md-5 pt-md-5">
                                <div className="col-lg-7 ps-md-4 pt-md-4 pe-md-0 bg-gray-light border-top-raduis fade-in-element left">
                                    <img src="/img/solution/enterprise-marketing.svg" alt="" width="100%" />
                                </div>
                                <div className="col-lg-4 ms-auto">
                                    <div className="content-text-copilot fade-in-element right">
                                        <h3>Marketing & Sales </h3>
                                        <p>Imagine a sales team equipped with an AI copilot that can analyze customer data, identify potential leads, and even generate personalized marketing messages tailored to each customer's preferences and behavior. This copilot could also provide real-time feedback and suggestions during sales calls, helping reps close deals more quickly and effectively.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row flex-row-reverse mt-md-5 pt-md-5">
                                <div className="col-lg-7 ps-md-4 pt-md-4 pe-md-0 bg-gray-light border-top-raduis fade-in-element right">
                                    <img src="/img/solution/enterprise-hr.svg" alt="" width="100%" />
                                </div>
                                <div className="col-lg-4 me-md-auto">
                                    <div className="content-text-copilot fade-in-element left">
                                        <h3>Human Resources</h3>
                                        <p>Our Human Resources Copilot can revolutionize the hiring process by sifting through resumes, identifying top candidates, and even conducting initial interviews. This copilot could help HR professionals make more informed hiring decisions, reduce bias, and save valuable time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Why Neuralogix--> */}
                <section className="mt-md-5 pt-md-4">
                    <div className="container px-lg-4 ">
                        <div className="col-lg-12 mx-auto my-lg-5 pt-sm-2 my-2 ">
                            <div className="col-lg-12">
                                <h2 className="career-headingwhyjoin">Why Neuralogix Enterprise AI </h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container-fluid">
                        <div>
                            <div className="row">
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack fade-in-element left">
                                        <div className="card-body px-lg-5 py-4">
                                            <img src="/img/industries-retailer/cardicon1.png" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Explainable AI</h3>
                                            <p className="industries-retailerpara">
                                                To ensure transparency and trustworthiness, our solution includes advanced explainability features that provide insights into how AI-driven decisions are made. This helps organizations understand the reasoning behind the AI's recommendations, fostering confidence in its abilities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4 fade-in-element right">
                                        <div className="card-body px-lg-5 px-sm-2 py-4">
                                            <img src="/img/industries-retailer/keypage.png" alt="" className="my-3" />
                                            <h3 className="industries-textheading">Security and Compliance</h3>
                                            <p className="industries-paratext">
                                                We prioritize security and compliance, adhering to industry-specific regulations and standards  to safeguard sensitive data and maintain privacy. Our solution is designed to address concerns around data ownership, control, and ethical usage.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4 fade-in-element left">
                                        <div className="card-body px-lg-5 px-sm-2 py-4">
                                            <img src="/img/solution/custom-icon.svg" alt="" className="my-3" />
                                            <h3 className="industries-textheading">Customization and Integration</h3>
                                            <p className="industries-paratext">
                                                Our solution can be tailored to suit individual organization needs, integrating seamlessly with existing systems, workflows, and infrastructure. This ensures minimal disruption to operations while maximizing the impact of AI-driven innovation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack fade-in-element right">
                                        <div className="card-body px-lg-5 py-4">
                                            <img src="/img/solution/ContinuousImprovement.png" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Continuous Improvement</h3>
                                            <p className="industries-retailerpara">
                                                Finally, we offer ongoing training and updates to ensure our solution remains at the forefront of AI advancements. This guarantees that clients receive the most effective and efficient AI-powered enterprise co-pilot experience possible
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Pathbreaking Pilots--> */}
                <section>
                    <div className="container px-lg-4">
                        <div className="col-lg-12 mx-auto mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-2 px-0">
                            <h2 className="career-headingwhyjoin pb-md-5 pb-3 mb-lg-3">Pathbreaking Pilots</h2>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="case-study-img">
                        <img src="/img/solution/solution-enterprize-midbannerimag.png" alt="" width="100%" className="fade-in-element left" />
                        <div className="case-study-content fade-in-element right">
                            <div className="owl-carousel owl-theme">
                                <div className="item">
                                    <div className="content-casestudy">
                                        <p>Our Recruitment Copilot has transformed the hiring process at a top workforce solutions firm, swiftly analyzing thousands of CVs to pinpoint ideal candidates. More than keyword matching, it comprehends job nuances. Additionally, it conducts real-time preliminary interviews, fast-tracking recruitment.</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="content-casestudy">
                                        <p>Our Recruitment Copilot has transformed the hiring process at a top workforce solutions firm, swiftly analyzing thousands of CVs to pinpoint ideal candidates. More than keyword matching, it comprehends job nuances. Additionally, it conducts real-time preliminary interviews, fast-tracking recruitment.</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="content-casestudy">
                                        <p>Our Recruitment Copilot has transformed the hiring process at a top workforce solutions firm, swiftly analyzing thousands of CVs to pinpoint ideal candidates. More than keyword matching, it comprehends job nuances. Additionally, it conducts real-time preliminary interviews, fast-tracking recruitment.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <button className="custom-prev-button"><i className="fa-solid fa-arrow-left-long"></i></button>
                        <button className="custom-next-button"><i className="fa-solid fa-arrow-right-long"></i></button> */}
                        </div>
                    </div>
                </section>
                <div className="my-lg-5 py-lg-5"></div>
            </main>

            {/* <!-- Footer match --> */}
            <section className="hero-bannercareer border-bottomfooter fade-in-element">
                <div className="container py-4">
                    <div className="col-lg-12 mx-auto">
                        <div className="row py-md-5 py-2">
                            <div className="col-lg-12 fade-in-element left">
                                <h1 className="chat-llm">Supercharge your business with AI Copilots</h1>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-3  mt-sm-2">
                                <span 
                                        onClick={handleRequestDemoClick} className="btn btn-consulation">Book a Consultation</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default SolutionsEnterpriseAICopilots
