import React, { useEffect } from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link } from 'react-router-dom'
import { AddNewsLetterEmail } from '../service/NewsLetterEmailService'
import Swal from 'sweetalert2'
import GeneralUtils from '../utils/GeneralUtils'
import Loader from '../component/Loader'

const Insight = () => {

    const [email, setEmail] = React.useState("");
    const [error, setError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setError(true);
            return;
        } else if (!GeneralUtils.validationForEmail(email)) {
            setError(true);
            return;
        }

        setIsLoading(true);

        try {
            const response = await AddNewsLetterEmail({ email: email });
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'You have successfully subscribed to our blogs',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                });
                setError(false);
            }
            setEmail("");
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                text: error?.response?.data?.message || 'Something Went Wrong',
            });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <Header activelink='insights' />

            {isLoading && <Loader />}

            <main className="pb-lg-1 pb-sm-1">
                {/* <!--Page Header--> */}
                <section style={{ background: 'linear-gradient(110deg, #000 -12.66%, rgba(25, 24, 23, 0.98) 109.42%)' }}>
                    <div className="container-fluid">
                        <div className="row ps-md-5">
                            <div className="col-lg-8 ps-md-5">
                                <div className="bg-image-and-text ps-lg-5 pt-lg-5 fade-in-element left">
                                    <h1 className="insightbannertext pt-lg-5 mt-lg-5">A CEO's Guide to <br /> Generative AI</h1>
                                    <Link to="/blog1" className="ctabtn">Read More</Link>
                                </div>
                            </div>
                            <div className="col-lg-4 bg-gray-insight">
                                <div className="signup-insight">
                                    <p>Want our blogs delivered to your inbox? </p>
                                    <h4>Sign up</h4>
                                    <div className="d-flex justify-content-between  align-items-center">
                                        <input type="email" className="form-control extracss" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Business Email" />
                                        <button className="btn bg-dark rounded-circle" onClick={handleSubmit}><i className="fa-solid fa-arrow-right-long text-white"></i></button>

                                    </div>

                                    <hr />
                                    <div className="errorbox">
                                        {!email && error && <p style={{ color: 'red', fontSize: '14px', margin: '0' }}>**Email is Required</p>}
                                        {email && (!GeneralUtils.validationForEmail(email) && error
                                            && <p style={{ color: 'red', fontSize: '14px', margin: '0' }}>**Invalid Email</p>)}
                                    </div>

                                </div>

                                <div className="padding-insight fade-in-element right">
                                    <Link to="/blog6" className="bloglink">
                                        <div>
                                            <p className="text-aiinsight">6 Generative AI Business Myths that will make you Rethink Everything</p>
                                            <span className="small-textjaisa">June 8, 2023 | 4 min read</span>
                                        </div>
                                    </Link>

                                    <Link to="/blog2" className="bloglink">
                                        <div className="mt-lg-5">
                                            <p className="text-aiinsight">Generative AI vs. Traditional Machine Learning: What's the Difference?</p>
                                            <span className="small-textjaisa">July 25, 2023 | 3 min read</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                {/* <!--Blogs--> */}
                <section className="my-md-5 my-3 pt-md-5">
                    <div className="container px-lg-4">
                        <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
                            <div className="row justify-content-center ">
                                <div className="col-lg-5 fade-in-element left mb-4">
                                    <Link to="/blog3">
                                        <img src="/img/insight/insightcar1.png" alt="" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-lg-5 fade-in-element right">
                                    <Link to="/blog3" className="bloglink">
                                        <div className="insight-text-card py-lg-5  px-md-4">
                                            <p>October 16, 2023 | 4 min read</p>
                                            <h3>How to Implement Generative AI in Your Enterprise</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-11  mx-auto border-bottom pb-5 mt-lg-5 mb-4">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 fade-in-element left mb-4">
                                    <Link to="/blog4">
                                        <img src="/img/insight/insightcard2.png" alt="" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-lg-5 fade-in-element right">
                                    <Link to="/blog6" className="bloglink">
                                        <div className="insight-text-card py-lg-5 px-md-4">
                                            <p>September 12, 2023 | 3 min read</p>
                                            <h3>Navigating the Ethical Frontier of AI: Balancing Progress with Responsibility</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-11 mx-auto pb-5 mt-lg-5 border-bottom mb-4">
                            <div className="row justify-content-center pb-md-5">
                                <div className="col-lg-5 fade-in-element left mb-4">
                                    <Link to="/blog5">
                                        <img src="/img/insight/insightcard3.png" alt="" width="100%" />
                                    </Link>
                                </div>
                                <div className="col-lg-5 fade-in-element right">
                                    <Link to="/blog5" className="bloglink">
                                        <div className="insight-text-card py-lg-5  px-md-4">
                                            <p>August 18, 2023 | 3 min read</p>
                                            <h3>The Rise of Explainable AI: How We're Making AI More Transparent and Trustworthy</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export default Insight
