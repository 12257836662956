import React from 'react';
import { Spinner } from 'react-bootstrap';
const Loader = () => {
  return (
    <div id="ngixloader1" className="loader-container">
      <img src="img/loader.png" alt="Loading..." className="ngixloader1" />
      {/* Add Bootstrap spinner */}
      {/* <Spinner animation="border" role="status" variant="dark">
        <span className="sr-only">Loading...</span>
      </Spinner> */}
    </div>
  );

}

export default Loader;