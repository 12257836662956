import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { getJobList } from '../service/careerService';
import { Link } from 'react-router-dom';
import Loader from '../component/Loader';


const Careers = () => {
    const [jobs, setJobs] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        getJobList().then((res) => {
            setJobs(res.data);
            setLoader(false);
        }).catch((err) => { 
            setLoader(false);
        });

    }, []);


    const scrollToOpenPositions = () => {
        const elmnt = document.getElementById("open-positions");
        elmnt.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header activelink='careers' />

            <main className="pb-lg-1 pb-sm-1" style={{ backgroundColor: '#fff' }}>
                {/* <!--Page Banner--> */}
                <section className="hero-bannercareer">
                    <div className="container py-lg-5">
                        <div className="col-xxl-12 mx-auto">
                            <div className="row py-5">
                                <div className="col-xxl-7 col-lg-10 col-md-12 fade-in-element left">
                                    <h1 className="Careers-herobannertext" style={{ lineHeight: '93px' }}>Let’s build our future, together</h1>
                                    <p className="lead-careertext">Work with talented data scientists, engineers, and thought leaders to change how businesses work.</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                        <Link onClick={scrollToOpenPositions} className="btn btn-careerseeopen">See open positions</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="img/careerimage/career-bannershort.png" alt="" className="circle-elipsse-retailer" />
                </section>

                {/* <!--Para--> */}
                <section className="bg-gray-light py-5">
                    <div className="my-lg-5 pt-sm-2 px-md-5 px-3 my-sm-2 container-fluid ">
                        <div className="col-xxl-11 col-md-12 mx-auto">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                                In a nutshell: People are at the heart of everything we do. While we're a passionate tech company that crafts cutting-edge software—and yes, we're proudly geeky about it—it's our dedication to our team that truly sets us apart. We prioritize the well-being and growth of our employees, striving to create a fulfilling, engaging, and unforgettable work journey for everyone on board. Join us, and be a part of a company that genuinely values you.
                            </p>
                        </div>
                    </div>
                </section>

                {/* <!--Career Image--> */}
                <section>
                    <img src="img/careerimage/middle-sectionimg.png" alt="" width="100%" />
                </section>

                {/* <!--Perks of working with us--> */}
                <section>
                    <div className=" py-lg-5 my-lg-5 container-fluid fade-in-element">
                        <div className="">
                            <div className="container">
                                <div className="col-xxl-11 col-md-12 mx-auto">
                                    <h2 className="career-headingwhyjoin mb-lg-5 mb-sm-2 pb-lg-4 pb-sm-1">Perks of working with us</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack">
                                        <div className="card-body px-md-5 px-3 py-4">
                                            <img src="/img/industries-retailer/cardicon1.png" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Benefits</h3>
                                            <p className="industries-retailerpara">
                                                Neuralogix provides comprehensive health, dental, and vision coverage, along with a robust retirement plan. We're committed to the well-being of our employees, both inside and outside the office.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                        <div className="card-body px-md-5 px-3 py-4">
                                            <img src="/img/industries-retailer/keypage.png" alt="" className="my-3" />
                                            <h3 className="industries-textheading">We invest in you</h3>
                                            <p className="industries-paratext">
                                                We offer ongoing learning opportunities such as workshops and mentorship programs. Your professional growth is directly tied to our success, and we provide the tools you need to excel.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                        <div className="card-body px-md-5 px-3 py-4">
                                            <img src="/img/industries-retailer/keypage.png" alt="" className="my-3" />
                                            <h3 className="industries-textheading">A world-class team</h3>
                                            <p className="industries-paratext">
                                                Join a team of AI experts and innovators from around the world. It's an unparalleled opportunity to collaborate on cutting-edge projects and network with industry leaders.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack">
                                        <div className="card-body px-md-5 px-3 py-4">
                                            <img src="/img/industries-retailer/cardicon1.png" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Fun and Flexible Working  </h3>
                                            <p className="industries-retailerpara">
                                                We prioritize work-life balance with flexible hours, remote work options, and regular team-building events. At Neuralogix, we create an environment where creativity and teamwork flourish.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Explore our Open Positions--> */}
                <section id='open-positions' className="mt-lg-5 pb-lg-5">
                    <div className="container-fluid px-md-5 px-3">
                        <div className="col-xxl-11 col-md-12 mx-auto">
                            <h2 className="career-headingwhyjoin pb-4 border-bottom">Explore our Open Positions</h2>
                            {
                                jobs.length === 0 && loader === false && <div className="text-center">No positions are currently available. Please check back for future opportunities.</div>
                            }
                            {
                                loader &&  <Loader />
                            }
                            {jobs.length > 0 && jobs.map((job, index) => (
                                <div key={index} className="d-flex justify-content-between border-bottom">
                                    <div className="pt-3 pb-4">
                                        <p className="data-textcareer-heading">   <Link to={`/careers-opening?jobid=${job.id}`}>{job.job_title}</Link></p>
                                        <span className="location-careertex">{job.job_location}</span>
                                    </div>
                                    <div className="icon-accroding my-3">
                                        <Link to={`/careers-opening?jobid=${job.id}`}>
                                            <img src="/img/careerimage/rigth-arrow-black.svg" alt="" />
                                        </Link>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export default Careers