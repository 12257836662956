import React, { useEffect } from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link, useNavigate } from 'react-router-dom'

const IndustriesManufacturing = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate()
    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Manufacturing",  // Replace this with your actual data
            },
          });
    }

    return (
        <>
            <Header activelink='industries' />

            <main style={{ backgroundColor: '#fff' }}>
                {/* <!--Page Banner--> */}
                <section className="hero-bannercareer">
                    <div className="container py-lg-5">
                        <div className="col-xxl-12 mx-auto">
                            <div className="row py-5">
                                <div className="col-xxl-8 col-lg-10 fade-in-element left">
                                    <p className="small__by_text"> <Link to="/industries-finance"> Industries /</Link> <span> <Link to="/industries-manufacturing">Manufacturing</Link></span></p>
                                    <h1 className="Careers-herobannertext">Crafting Tomorrow's Factories</h1>
                                    <p className="lead-careertext">Let generative AI be your digital twin, optimizing processes, enhancing product quality, and driving innovation in real-time.</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                        <span onClick={ handleDemoClick }  className="btn btn-careerseeopen">Request a demo</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/img/industries-retailer/manufactureing/manufactureing-shortbanner.png" alt="" className="circle-elipsse-retailer" />
                </section>

                {/* <!--Para--> */}
                <section className="bg-gray-light py-5">
                    <div className="px-lg-5 pt-sm-2 px-sm-2 my-sm-2 container-fluid">
                        <div className="col-lg-11 mx-auto">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                                The manufacturing industry is in a constant state of evolution, driven by technological advancements, changing market dynamics, and the pursuit of operational excellence. To thrive in this dynamic landscape, manufacturers require innovative solutions that optimize processes, improve product quality, and drive operational efficiency. This is where our advanced AI solutions come into play.
                            </p>
                        </div>
                    </div>
                </section>

                {/* <!--AI Solutions for Manufacturing--> */}
                <section>
                    <div className="py-lg-5 my-lg-5 container-fluid fade-in-element">
                        <div>
                            <div className="container-fluid px-lg-5">
                                <div className="col-lg-11 mx-auto">
                                    <h2 className="career-headingwhyjoin mb-lg-5 mb-sm-2 pb-lg-4 pb-sm-1">AI Solutions for Manufacturing</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack">
                                        <div className="card-body px-lg-5 py-4">
                                            <img src="/img/industries-retailer/manufactureing/icon-manufactureing1.svg" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Predictive Maintenance</h3>
                                            <p className="industries-retailerpara">
                                                Utilize AI algorithms to predict equipment failures and plan maintenance activities proactively.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                        <div className="card-body px-lg-5 px-sm-2 py-4">
                                            <img src="/img/industries-retailer/manufactureing/icon-manufactureing2.svg" alt="" className="my-3" />
                                            <h3 className="industries-textheading">Quality Control</h3>
                                            <p className="industries-paratext">
                                                Implement AI-powered vision systems to detect defects and ensure product quality consistency.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                        <div className="card-body px-lg-5 px-sm-2 py-4">
                                            <img src="/img/industries-retailer/manufactureing/icon-manufactureing3.svg" alt="" className="my-3" />
                                            <h3 className="industries-textheading">Supply Chain Analytics</h3>
                                            <p className="industries-paratext">
                                                Gain valuable insights into your supply chain operations, optimize inventory management, and enhance supplier relationships.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack">
                                        <div className="card-body px-lg-5 py-4">
                                            <img src="/img/industries-retailer/manufactureing/icon-manufactureing4.svg" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Knowledge Management </h3>
                                            <p className="industries-retailerpara">
                                                Facilitate knowledge transfer, ensuring that best practices and expert insights are seamlessly accessible across teams and shifts.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Manufacturing Precision with Quantified Gains--> */}
                <section className="mt-lg-5 pb-lg-5 mb-lg-5 pt-lg-4">
                    <div className="container-fluid px-lg-5">
                        <div className="col-lg-11 mx-auto">
                            <h2 className="career-headingwhyjoin">Manufacturing Precision with  <br />Quantified Gains</h2>
                        </div>
                    </div>
                    <div className="container-fluid mt-md-5 mt-2 px-lg-4">
                        <div className="col-lg-11 mx-auto">
                            <div className="row">
                                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible">
                                        <h5>Uplift in Production Efficiency</h5>
                                        <p>Optimized production lines powered by AI can lead to a 25% increase in output with the same resources.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible">
                                        <h5>Reduced Equipment Downtime</h5>
                                        <p>Predictive maintenance can decrease equipment downtime by up to 30%, ensuring smoother operations.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible">
                                        <h5>Supply Chain Savings</h5>
                                        <p>Enhanced forecasting and supplier relations can potentially cut supply chain costs by 20%.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Trust Neuralogix--> */}
                <section className="industries-impactai">
                    <div className="container">
                        <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                            <div className="card-carouselindutries fade-in-element right">
                                <h3 className="pt-lg-5 mb-lg-4">Trust Neuralogix for Secure and Scalable Solutions</h3>
                                <p><b className="text-white">Secure & Scalable Framework:</b> Data security is paramount in today's digital age. Neuralogix's commitment to hosting purpose-tuned, Large Language Models in private clouds ensures that our clients’ sensitive data remains confidential</p>
                                <p>
                                    <b className="text-white">Customized Generative AI Solutions:</b> We don't just offer a one-size-fits-all AI solution. Our strength lies in recognizing the unique needs of different industries and customizing our AI platforms to specifically cater to those needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <!-- Footer match --> */}
            <section className="hero-bannercareer border-bottomfooter fade-in-element">
                <div className="container py-4">
                    <div className="col-lg-12 mx-auto">
                        <div className="row py-md-5 py-2">
                            <div className="col-lg-12">
                                <h1 className="chat-llm">Enhance Manufacturing Efficiency</h1>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                                <span onClick={ handleDemoClick }  className="btn btn-consulation">Book a Consultation</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default IndustriesManufacturing
