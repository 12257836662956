import React, { useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery"; // Make sure to import jQuery

const SolutionsAIChatBots = () => {
    const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".improve-customer-sectionaccordian").on("click", function () {
        // Close all sections
        $(".improve-customer-sectionaccordian").addClass("collapsed");
        $(".collapse").removeClass("show");

        // Open the clicked section
        $(this).removeClass("collapsed");
        $(this).find(".collapse").addClass("show");

        // Print the accordion index which is open
        var index = $(this).index();

        // Check which accordion is open
        if (index === 0) {
          $("#accordianContent1").addClass("show").fadeIn(1000);
          $("#accordianContent2").removeClass("show");
          $("#accordianContent3").removeClass("show");
        } else if (index === 1) {
          $("#accordianContent1").removeClass("show");
          $("#accordianContent2").addClass("show").fadeIn(1000);
          $("#accordianContent3").removeClass("show");
        } else if (index === 2) {
          $("#accordianContent1").removeClass("show");
          $("#accordianContent2").removeClass("show");
          $("#accordianContent3").addClass("show").fadeIn(1000);
        }
      });
    });
  }, []); // Empty dependency array ensures this runs once after the initial render

const handleDemoClick = () => {
    navigate('/contact', {
        state: {
          demoNeededFor: "AI Chatbots",  // Replace this with your actual data
        },
      });
}
    

  return (
    <>
      <Header activelink="solutions" />

      <main className="pb-lg-1 pb-sm-1">
        {/* <!--Page Banner--> */}
        <section className="hero-bannercareer">
          <div className="container py-lg-5">
            <div className="col-lg-12 mx-auto">
              <div className="row py-5">
                <div className="col-xxl-7 col-lg-8 fade-in-element left">
                  <p className="small__by_text">
                    {" "}
                    <Link to="/solutions-ai-chatbots"> Solutions /</Link>{" "}
                    <span>
                      <Link to="/solutions-ai-chatbots">AI Chatbots</Link>
                    </span>
                  </p>

                  <h1 className="Careers-herobannertext">AI Chatbots</h1>
                  <p className="lead-careertext">
                    Deliver outstanding customer experience, cut costs and
                    automate at scale with highly intelligent Chatbots
                  </p>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                    <span  onClick={()=>handleDemoClick()}
                    className="btn btn-careerseeopen">
                      Request a demo
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="img/home/circle-drw.png"
            alt=""
            width="100%"
            className="circle-elipsse"
          />
        </section>

        {/* <!--Para--> */}
        <section className="solution-section">
          <div className=" py-md-5 py-3 pt-sm-2 px-sm-2 container">
            <div className="col-lg-12 mx-auto">
              <div className="ai-chatsolution py-md-5 py-2 fade-in-element bottom">
                <p>
                  Juniper Research predicts chatbots will save businesses in
                  retail, banking, and healthcare a staggering{" "}
                  <span>$11 billion this year.</span> Join the revolution with
                  Neuralogix's cutting-edge Generative AI Chatbot and
                  supercharge your customer service today!
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Chat Section--> */}
        <section>
          <div className="container pt-md-5">
            <div className="col-lg-12 mx-auto my-lg-5 pt-sm-2 my-sm-2">
              <div className="col-lg-10 ">
                <h2 className="career-headingwhyjoin">
                  Natural-sounding, human-like chat and voice bots that drive
                  entire customer journey
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="col-lg-12 mx-auto">
              <div className="row">
                <div className="col-lg-6 fade-in-element left">
                  <div className="col-lg-10">
                    <div className="reduced">
                      <div
                        className="p-md-4 p-2 mt-lg-2 improve-customer-sectionaccordian"
                        data-bs-toggle="collapse"
                        href="#collapseExampleim"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExampleim"
                      >
                        <h4 className="">Improved customer satisfaction</h4>
                        <div className="collapse show " id="collapseExampleim">
                          <p>
                            Designed to be user-friendly and engaging, providing
                            your customers with the information and assistance
                            they need quickly and easily.
                          </p>
                        </div>
                      </div>
                      <div
                        className="p-md-4 p-2 mt-lg-2 improve-customer-sectionaccordian collapsed"
                        data-bs-toggle="collapse"
                        href="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <h4 className="">Reduced customer support costs</h4>
                        <div className="collapse" id="collapseExample">
                          <p>
                            Automate many of the repetitive tasks that your
                            customer support team currently handles.
                          </p>
                        </div>
                      </div>

                      <div
                        className="p-md-4 p-2 mt-lg-2 improve-customer-sectionaccordian collapsed"
                        data-bs-toggle="collapse"
                        href="#collapseExample1"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample1"
                      >
                        <h4>Increased sales and conversions</h4>
                        <div className="collapse" id="collapseExample1">
                          <p>
                            Qualify leads, nurture prospects, and close deals.
                            This can help you increase your sales and
                            conversions and boost your bottom line.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div
                    className="position-relative collapse show fade-in-element right"
                    id="accordianContent1"
                  >
                    <div className="text-end">
                      <img
                        src="/img/solution/chat-section.png"
                        alt=""
                        width="60%"
                      />
                    </div>

                    <div className="chat-boxs">
                      <div className="box-1">
                        Hello! How can I assist you today?
                      </div>

                      <div className="box-2">
                        I'm trying to find my order status but I'm lost.
                      </div>
                      <div className="box-3">
                        No worries! Just give me your order number, and I'll
                        fetch the status for you right away.
                      </div>
                    </div>
                  </div>

                  <div
                    className="position-relative collapse fade-in-element right"
                    id="accordianContent2"
                  >
                    <div className="text-end">
                      <img
                        src="/img/solution/girl-ai-chat.png"
                        alt=""
                        width="60%"
                      />
                    </div>

                    <div className="chat-boxs">
                      <div className="box-1 box-whcontrol-1">
                        Hi there! I see you've added a cocktail dress to your
                        cart. Would you like to see matching accessories?
                      </div>

                      <div className="box-2 box-whcontrol-2">
                        Sure, why not.
                      </div>
                      <div className="box-3">
                        Great! Our pearl necklace and clutch bag would complete
                        the look. Would you like to add them to your cart?
                      </div>
                    </div>
                  </div>

                  <div
                    className="position-relative collapse fade-in-element right"
                    id="accordianContent3"
                  >
                    <div className="text-end">
                      <img
                        src="/img/solution/oldmen-chatai.png"
                        alt=""
                        width="60%"
                      />
                    </div>

                    <div className="chat-boxs">
                      <div className="box-1">
                        Welcome back! How can I assist you today?
                      </div>

                      <div className="box-2">
                        I want to change my subscription plan.
                      </div>
                      <div className="box-3">
                        Of course! You can switch to our Premium Plan at
                        $15/month or our Enterprise Plan at $25/month. Which one
                        suits you?
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Chatbots apart--> */}
        <section>
          <div className="container">
            <div className="col-lg-12 mx-auto my-lg-5 pt-sm-2 my-2">
              <div className="col-lg-6">
                <h2 className="career-headingwhyjoin">
                  What sets our Chatbots apart{" "}
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="">
              <div className="row">
                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                  <div className="card card-customindustriesdack fade-in-element left">
                    <div className="card-body px-lg-5 py-4">
                      <img
                        src="/img/solution/card-sloution001.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-retailercardheading">
                        Uncompromising Security
                      </h3>
                      <p className="industries-retailerpara">
                        We design chatbots with enterprise security in mind. We
                        use the latest security technologies and best practices
                        to protect your data and systems. We also offer a
                        variety of security features to meet the specific needs
                        of your enterprise, such as integration with existing
                        security systems, role-based access control, and data
                        encryption.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 p-0">
                  <div className="card card-customindustries mt-lg-0 mt-sm-4 fade-in-element right">
                    <div className="card-body px-lg-5 px-sm-2 py-4">
                      <img
                        src="/img/solution/card-solution002.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-textheading">
                        Custom-Built for your Enterprise
                      </h3>
                      <p className="industries-paratext">
                        We work with you to understand your business goals and
                        customer needs and design a chatbot that is tailored to
                        your unique requirements, integrates seamlessly with
                        your CRM and ERP systems, and provides customized
                        analytics and reporting capabilities..
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 p-0">
                  <div className="card card-customindustries mt-lg-0 mt-sm-4 fade-in-element left">
                    <div className="card-body px-lg-5 px-sm-2 py-4">
                      <img
                        src="/img/solution/card-solution003.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-textheading">
                        Omnichannel Support
                      </h3>
                      <p className="industries-paratext">
                        Neuralogix chatbots can be integrated with a wide range
                        of omnichannel platforms, including your website, mobile
                        app, social media, and messaging apps. This allows your
                        customers to interact with your chatbot on their
                        preferred channel, whenever and wherever they need
                        assistance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                  <div className="card card-customindustriesdack fade-in-element right">
                    <div className="card-body px-lg-5 py-4">
                      <img
                        src="/img/solution/card-solution004.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-retailercardheading">
                        Continuous Learning & Improvement
                      </h3>
                      <p className="industries-retailerpara">
                        Neuralogix chatbots are designed to continuously learn
                        and improve over time. We use a variety of machine
                        learning techniques to train our chatbots to better
                        understand and respond to customer queries. We also
                        collect feedback from our customers and use it to
                        improve our chatbots on an ongoing basis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Pathbreaking Pilots--> */}
        <section>
          <div className="container">
            <div className="col-lg-12 mx-auto mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-2 px-0">
              <h2 className="career-headingwhyjoin pb-4 mb-lg-5">
                Pathbreaking Pilots
              </h2>
            </div>
          </div>
        </section>

        <section className="">
          <div className="case-study-img">
            <img
              src="/img/solution/sub-banner.png"
              alt=""
              width="100%"
              className="fade-in-element left"
            />
            <div className="case-study-content fade-in-element right">
              <div className="owl-carousel owl-theme">
                <div className="item">
                  <div className="content-casestudy">
                    <p>
                      Neuralogix helped a leading e-commerce company to develop
                      a chatbot that could answer customer questions about
                      products and services, help customers place orders, and
                      track shipments. The chatbot has been a huge success, and
                      it has helped the company to reduce customer support costs
                      and increase customer satisfaction.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="content-casestudy">
                    <p>
                      Neuralogix helped a leading e-commerce company to develop
                      a chatbot that could answer customer questions about
                      products and services, help customers place orders, and
                      track shipments. The chatbot has been a huge success, and
                      it has helped the company to reduce customer support costs
                      and increase customer satisfaction.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="content-casestudy">
                    <p>
                      Neuralogix helped a leading e-commerce company to develop
                      a chatbot that could answer customer questions about
                      products and services, help customers place orders, and
                      track shipments. The chatbot has been a huge success, and
                      it has helped the company to reduce customer support costs
                      and increase customer satisfaction.
                    </p>
                  </div>
                </div>
              </div>
              {/* <button className="custom-prev-button"><i className="fa-solid fa-arrow-left-long"></i></button>
                            <button className="custom-next-button"><i className="fa-solid fa-arrow-right-long"></i></button> */}
            </div>
          </div>
        </section>
        <div className="my-lg-5 py-lg-5"></div>
      </main>

      {/* <!-- Footer match --> */}
      <section className="hero-bannercareer border-bottomfooter">
        <div className="container py-4">
          <div className="col-lg-12 mx-auto">
            <div className="row py-md-5 py-2">
              <div className="col-lg-12 fade-in-element left">
                <h1 className="chat-llm">
                  Optimize Your Customer Support Costs
                </h1>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                <span  onClick={()=>handleDemoClick()} className="btn btn-consulation">
                    Book a Consultation
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SolutionsAIChatBots;
