import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import ApplyNowModal from '../component/ApplyNowModal';
import { getJobById, applyForJob } from '../service/careerService';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import Loader from '../component/Loader';

const CareersOpening = () => {
    //get job id from query params
    const query = new URLSearchParams(useLocation().search);
    const id = query.get('jobid');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [jobData, setJobData] = useState({});
    const [loader, setLoader] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (formData) => {
        // Handle the form data submission here
        formData.job_id = id;
        // You can also close the modal after submission if needed
        try {
            setLoader(true);
            await applyForJob(formData);
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Congratulations! You have successfully applied for the position.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
            handleCloseModal();
            setLoader(false);

        }
        catch (ex) {
            Swal.fire({
                icon: 'error',
                text: ex?.response?.data?.message || 'Something Went Wrong',
            });
            handleCloseModal();
            setLoader(false);
        }


    };

    React.useEffect(() => {
        if (!id)
            return;
        const getJobData = async () => {
            const jobData = await getJobById(id);
            setJobData(jobData?.data);
        }
        getJobData();
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <Header activelink='careers' />

            {loader && <Loader />}

            <main className="pb-lg-1 pb-sm-1" style={{ backgroundColor: '#fff' }}>
                {/* <!--Page Banner--> */}
                <section className="hero-bannercareer">
                    <div className="container py-lg-5">
                        <div className="row py-5">
                            <div className="col-xxl-7 col-lg-9 fade-in-element left applynowbox">
                                <p className="small__by_text">
                                    <Link to="/careers">Careers / </Link>
                                    <span><Link>Current Openings</Link></span>
                                </p>
                                <h1 className="Careers-herobannertext">{jobData.job_title}</h1>
                                <p className="lead-careertext">{jobData.location}</p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                    <button type="button" className="btn btn-careerseeopen" onClick={handleOpenModal}>Apply now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/img/career-opening/career-openingshort-banner.png" alt="" className="circle-elipsse-retailer" />
                </section>

                {/* <!--Job description--> */}
                <section>
                    <div className="px-lg-5 px-3 py-md-5 my-md-5 my-1 container-fluid">
                        <div className="col-lg-11 mx-auto">
                            <h2 className="career-headingwhyjoin mb-lg-4">Job description</h2>
                            <p className="career-linejobd mb-4 fade-in-element left">
                                {jobData.introduction}
                            </p>
                        </div>
                    </div>
                </section>

                {/* <!--Career Image--> */}
                <section>
                    <img src="/img/career-opening/opening-midimg.png" alt="" width="100%" />
                </section>

                {/* <!--Why join us--> */}
                <section>
                    <div className="px-lg-4 py-lg-5 my-lg-5 container">
                        <div className="col-lg-10 mx-auto">
                            <h2 className="career-headingwhyjoin mb-lg-4 mb-sm-2 pb-lg-3 pb-sm-1">What you’ll be doing</h2>
                            <div className="row fade-in-element">
                                <div className="col-lg-12 mt-lg-0 mt-sm-3">
                                    <div className="card card-custom">
                                        <div className="card-body px-lg-5 py-4 careerjd">
                                            <div dangerouslySetInnerHTML={{ __html: jobData.what_u_will_do }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-10 mx-auto mt-lg-5">
                            <h2 className="career-headingwhyjoin mb-lg-4 mb-sm-2 pb-lg-3 pb-sm-1">Job requirements</h2>
                            <div className="row fade-in-element">
                                <div className="col-lg-12 mt-lg-0 mt-sm-3">
                                    <div className="card card-custom">
                                        <div className="card-body px-lg-5 py-4 careerjd">
                                            <div dangerouslySetInnerHTML={{ __html: jobData.job_requirement }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-10 mx-auto mt-lg-5">
                            <h2 className="career-headingwhyjoin mb-lg-4 mb-sm-2 pb-lg-3 pb-sm-1">Nice to have</h2>
                            <div className="row fade-in-element">
                                <div className="col-lg-12 mt-lg-0 mt-sm-3">
                                    <div className="card card-custom">
                                        <div className="card-body px-lg-5 py-4 careerjd">
                                            <div dangerouslySetInnerHTML={{ __html: jobData.nice_to_have }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-10 mx-auto mt-lg-5">
                            <h2 className="career-headingwhyjoin mb-lg-4 mb-sm-2 pb-lg-3 pb-sm-1">What we offer</h2>
                            <div className="row fade-in-element">
                                <div className="col-lg-12 mt-lg-0 mt-sm-3">
                                    <div className="card card-custom">
                                        <div className="card-body px-lg-5 py-4 careerjd">
                                            <div dangerouslySetInnerHTML={{ __html: jobData.what_we_offer }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 pb-5">
                                <button className="apply-nowbtn" onClick={handleOpenModal} >Apply now</button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

            <ApplyNowModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}
                jobDetail={{ position: jobData.job_title, jobLocation: jobData.location }} loaders={loader} onSubmit={handleSubmit} />
        </>
    )
}

export default CareersOpening