import React, { useEffect } from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link, useNavigate } from 'react-router-dom'

const IndustriesHealthCare = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Healthcare",  // Replace this with your actual data
            },
          });
    }

    return (
        <>
            <Header activelink='industries' />

            <main style={{ backgroundColor: '#fff' }}>
                {/* <!--Page Banner--> */}
                <section className="hero-bannercareer">
                    <div className="container py-lg-5">
                        <div className="col-xxl-12 mx-auto">
                            <div className="row py-5">
                                <div className="col-xxl-7 col-lg-10 fade-in-element left">
                                    <p className="small__by_text"> <Link to="/industries-finance"> Industries /</Link> <span><Link to="/industries-healthcare">Healthcare</Link></span></p>
                                    <h1 className="Careers-herobannertext">Revolutionizing Patient Care</h1>
                                    <p className="lead-careertext">Merging AI with healthcare, we offer cutting edge solutions that improve patient engagement and streamline healthcare operations.</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                        <span onClick={handleDemoClick} className="btn btn-careerseeopen">Request a demo</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/img/industries-retailer/Healthcare/health-care-bannershort.png" alt="" className="circle-elipsse-retailer" />
                </section>

                {/* <!--Para--> */}
                <section className="bg-gray-light">
                    <div className="px-lg-5 py-lg-5  pt-sm-2 px-sm-2 container-fluid ">
                        <div className="col-lg-11 mx-auto">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                                In an evolving healthcare landscape, organizations confront challenges like data fragmentation, operational inefficiencies, and varying patient experiences. Compounded by regulatory hurdles and the need for real-time data-driven decisions, these issues demand innovative solutions. With Neuralogix, healthcare institutions can unlock the true potential of their data, ensuring a future where technology and care work hand-in-hand.
                            </p>
                        </div>
                    </div>
                </section>

                {/* <!--AI Solutions for Financial Sector--> */}
                <section>
                    <div className=" py-lg-5 my-lg-5 container-fluid fade-in-element">
                        <div className="">
                            <div className="container-fluid  px-lg-5">
                                <div className="col-lg-11 mx-auto">
                                    <h2 className="career-headingwhyjoin mb-lg-5 mb-sm-2 pb-lg-4 pb-sm-1">AI Solutions for Healthcare</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack">
                                        <div className="card-body px-lg-5 py-4">
                                            <img src="/img/industries-retailer/Healthcare/icon-healthcare1.svg" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Medical Diagnosis</h3>
                                            <p className="industries-retailerpara">
                                                Utilize AI algorithms to assist in medical image analysis, early disease detection, and accurate diagnosis.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                        <div className="card-body px-lg-5 px-sm-2 py-4">
                                            <img src="/img/industries-retailer/Healthcare/icon-healthcare2.svg" alt="" className="my-3" />
                                            <h3 className="industries-textheading">Administrative Task Automation</h3>
                                            <p className="industries-paratext">
                                                Automate administrative tasks like appointment scheduling, billing, and claims processing.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                        <div className="card-body px-lg-5 px-sm-2 py-4">
                                            <img src="/img/industries-retailer/Healthcare/icon-healthcare3.svg" alt="" className="my-3" />
                                            <h3 className="industries-textheading">AI-Powered Mental Health Services</h3>
                                            <p className="industries-paratext">
                                                Virtual AI therapists, empathetic chatbots, and personalized therapeutic interventions to support mental well-being.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                                    <div className="card card-customindustriesdack">
                                        <div className="card-body px-lg-5 py-4">
                                            <img src="/img/industries-retailer/Healthcare/icon-healthcare4.svg" alt="" className="my-3" />
                                            <h3 className="industries-retailercardheading">Real Time Treatment Assistance </h3>
                                            <p className="industries-retailerpara">
                                                Real-time support to doctors, nurses and paramedics by fetching critical information during procedures or patient interactions
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Healthcare Excellence with Quantified Outcomes--> */}
                <section className="mt-lg-5 pb-lg-5 mb-lg-5 pt-lg-5">
                    <div className="container-fluid  px-lg-5">
                        <div className="col-lg-11 mx-auto">
                            <h2 className="career-headingwhyjoin">Healthcare Excellence with Quantified Outcomes</h2>
                        </div>
                    </div>

                    <div className="container-fluid  px-lg-5 mt-md-5 mt-2">
                        <div className="col-lg-11 mx-auto">
                            <div className="row">
                                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible ">
                                        <h5>Reduced Diagnosis Errors</h5>
                                        <p>AI-powered diagnostic assistance can lead to a potential 30% reduction in diagnostic errors, enhancing patient trust.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible">
                                        <h5>Operational Cost Savings</h5>
                                        <p>Streamlined administration and optimized treatments can lead to potential operational savings of up to 20%.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-2 fade-in-element left">
                                    <div className="card-driving-tangible">
                                        <h5>Increased Patient Throughput</h5>
                                        <p>Efficient processes can boost the number of patients treated by 15%, increasing overall healthcare service efficiency.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--Trust Neuralogix--> */}
                <section className="industries-impactai">
                    <div className="container">
                        <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                            <div className="card-carouselindutries fade-in-element right">
                                <h3 className="pt-lg-5 mb-lg-4">Trust Neuralogix for Secure and Scalable Solutions</h3>
                                <p><b className="text-white">Secure & Scalable Framework:</b> Data security is paramount in today's digital age. Neuralogix's commitment to hosting purpose-tuned, Large Language Models in private clouds ensures that our clients’ sensitive data remains confidential</p>
                                <p>
                                    <b className="text-white">Customized Generative AI Solutions:</b> We don't just offer a one-size-fits-all AI solution. Our strength lies in recognizing the unique needs of different industries and customizing our AI platforms to specifically cater to those needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <!-- Footer match --> */}
            <section className="hero-bannercareer border-bottomfooter fade-in-element">
                <div className="container py-4">
                    <div className="col-lg-12 mx-auto">
                        <div className="row py-md-5 py-2">
                            <div className="col-lg-12">
                                <h1 className="chat-llm">Transform Your Healthcare Operations</h1>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                                <span onClick={handleDemoClick} className="btn btn-consulation">Book a Consultation</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default IndustriesHealthCare
