import React,{useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'

const Company = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])    


  return (
    <>
        <Header activelink='company'/>

        <main>
            {/* <!--Page Banner--> */}
            <section className="hero-bannercareer">
                <div className="container py-lg-5 ">
                    <div className="col-xxl-12 mx-auto pb-md-4">
                        <div className="row py-5 fade-in-element left">
                            <div className="col-xxl-7 col-lg-10">
                                <h1 className="Careers-herobannertext">Your Partner in AI Innovation</h1>
                                <p className="lead-careertext">Cultivating a future where AI and human ingenuity drive growth.</p>
                            </div>
                        </div> 
                    </div>
                </div>                
                <img src="/img/company/banner-companyshort.png" alt="" className="circle-elipsse-retailer earth"/>
            </section>

            {/* <!--Para--> */}
            <section className="bg-gray-light py-5">
                <div className="px-lg-5 px-sm-2 my-sm-2 container-fluid">
                    <div className="col-lg-11 mx-auto">                      
                        <p className="lead-careerbylinecompay mb-md-4 mb-3 fade-in-bottom">
                            At Neuralogix, we leverage Artificial Intelligence to transform enterprise operations, crafting bespoke solutions that resonate with industry-specific challenges. Drawing on decades of tech expertise and forward-thinking innovation, we're on a mission to enable businesses to engage deeper, decide smarter, and operate seamlessly.
                        </p>                        
                    </div>
                </div>
            </section>

            {/* <!--Charter of the AI Revolutionaries--> */}
            <section className="my-md-5 my-2 pt-md-5 pt-2 fade-in-element">
                <div className="container-fluid">
                    <div className="container-fluid px-md-5">
                        <div className="col-lg-11 mx-auto">
                            <h2 className="career-headingwhyjoin mb-lg-5 pb-lg-4">Charter of the AI Revolutionaries</h2>                         
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-0">
                            <div className="moral-controlcard">
                                <div className="card card-customindustriesdack mt-lg-0 mt-sm-4">
                                    <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/company/company-icon1.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">AI for everyone</h3>
                                    <p className="industries-retailerpara">
                                        AI for all, no matter your tech skills.
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-0">
                            <div className="moral-controlcard">
                                <div className="card card-customindustries">
                                    <div className="card-body px-lg-5 py-4">                                        
                                        <img src="/img/company/company-icon2.svg" alt="" className="my-3"/>
                                        <h3 className="industries-textheading">AI for good</h3>
                                        <p className="industries-paratext ">
                                            AI to make the world a better place.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-0">
                            <div className="moral-controlcard">
                                <div className="card card-customindustriesdack mt-lg-0 mt-sm-4">
                                    <div className="card-body px-lg-5 px-sm-2 py-4">
                                        <img src="/img/company/company-icon3.svg" alt="" className="my-3"/>
                                        <h3 className="industries-retailercardheading">AI that's ethical and responsible</h3>
                                        <p className="industries-retailerpara">
                                            AI used in a good and fair way.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-0">
                            <div className="moral-controlcard">
                                <div className="card card-customindustries">
                                    <div className="card-body px-lg-5 py-4">                                        
                                        <img src="/img/company/company-icon4.svg" alt="" className="my-3"/>
                                        <h3 className="industries-textheading">AI that's inclusive and diverse</h3>
                                        <p className="industries-paratext ">
                                            AI for everyone, regardless of background or identity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-0">
                            <div className="moral-controlcard">
                                <div className="card card-customindustriesdack mt-lg-0 mt-sm-4">
                                    <div className="card-body px-lg-5 px-sm-2 py-4">
                                        <img src="/img/company/company-icon5.svg" alt="" className="my-3"/>
                                        <h3 className="industries-retailercardheading">AI that's creative and innovative</h3>
                                        <p className="industries-retailerpara">
                                        AI to solve problems in new and exciting ways.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-0">
                            <div className="moral-controlcard">
                                <div className="card card-customindustries">
                                    <div className="card-body px-lg-5 py-4">                                      
                                        <img src="/img/company/company-icon6.svg" alt="" className="my-3"/>
                                        <h3 className="industries-textheading">AI that's fun and engaging</h3>
                                        <p className="industries-paratext ">
                                            AI that's enjoyable to use.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Led by Experience--> */}
            <section className="mrgintop-5 py-2">
                <div className="px-lg-4 py-lg-5 mt-lg-5 pt-sm-2 px-sm-2 my-sm-2 container">
                    <div className="col-lg-12 mx-auto pt-lg-5">                      
                        <div className="row mb-4">
                            <div className='col-lg-12'>
                                <h2 className="career-headingwhyjoin mb-lg-4 font-sizecompanycontrol">Led by Experience and Innovation</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5">
                                
                                <p className="passionline mb-md-5 mb-3">
                                    Neuralogix emerges from the strategic alliance between Acompworld, a leader in AI and digital transformation from India, and Integration International, Inc. (i3), an end-to-end global technology services provider.
                                </p>
                                <p className="passionline mb-md-5 mb-3">
                                    This joint venture combines Acompworld's prowess in AI, cloud, and data analytics with i3's worldwide presence, focusing on transforming business AI applications.
                                </p>
                            </div>
                            <div className="col-lg-1">
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 fade-in-element right mb-4">
                                        <div className="image-meet-team">
                                            <img src="/img/company/pratap.png" alt=""/>
                                            <div className="caption"><span>Pratap Jayakar </span> <a href="https://www.linkedin.com/in/pratap-jayakar-3736b53/" target='blank'><img src="/img/linkdin-company.png" alt="" className="imgling-din"/></a></div>
                                            <div className="tittle pb-md-3 pb-2">Co-Founder</div>                                        
                                        </div>
                                    </div>        
                                    <div className="col-lg-4 col-md-6 fade-in-element right  mb-4">
                                        <div className="image-meet-team">
                                            <img src="/img/company/manish.png" alt=""/>
                                            <div className="caption"><span>Manish Shrivastava </span><a href="https://www.linkedin.com/in/manishshrivast/" target='blank'> <img src="/img/linkdin-company.png" alt="" className="imgling-din"/> </a></div>
                                            <div className="tittle pb-md-3 pb-2">Co-Founder</div>                                                
                                        </div>
                                    </div>
    
                                    <div className="col-lg-4 col-md-6 fade-in-element right  mb-4">
                                        <div className="image-meet-team">
                                            <img src="/img/company/mihir.png" alt=""/>
                                            <div className="caption"><span>Mihir Shrivastava </span><a className="active" href="https://www.linkedin.com/in/mihir-shrivastava-a2a1b7191/" target='blank'><img src="/img/linkdin-company.png" alt="" className="imgling-din"/></a></div>
                                            <div className="tittle pb-md-3 pb-2">Co-Founder </div>                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>    

        <Footer />
    </>
  )
}

export default Company
