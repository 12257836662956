import React,{useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


  return (
    <>
        <Header />

        <main className="pb-lg-1 pb-sm-1">
                <section className="hero-bannercareer">
                    <div className="container py-lg-5">
                        <div className="row py-5">
                            <div className="col-xxl-7 col-lg-9 fade-in-element left">                                
                                <h1 className="Careers-herobannertext">Privacy Policy</h1>                                
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="px-lg-4 pt-lg-5 my-lg-5 pt-sm-2 px-sm-2 my-sm-2 container">
                        <div className="col-lg-12 mx-auto">
                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Countries Served</h3>
                                <p>By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.</p>
                            </div>

                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Use License</h3>
                                <p>Permission is granted to temporarily download one copy of the materials (information or software) on Neuralogix AI's web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
                                <p>Modify or copy the materials.</p>
                                <ul className="parvacy-sub-menu-content">
                                    <li>Use the materials for any commercial purpose, or for any public display (commercial or non-commercial).</li>
                                    <li>Attempt to decompile or reverse engineer any software contained on Neuralogix AI's web site.</li>
                                    <li>Remove any copyright or other proprietary notations from the materials; or Transfer the materials to another person or “mirror” the materials on any other server.</li>
                                </ul>
                            </div>

                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Disclaimer</h3>
                                <ul className="parvacy-sub-menu-content">
                                    <li>The materials on Neuralogix AI's web site are provided “as is”. Neuralogix AI makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Neuralogix AI does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.</li>
                                    <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Neuralogix AI at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
                                </ul>
                            </div>

                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Limitations</h3>
                                <p>In no event shall Neuralogix AI or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on Neuralogix AI's Internet site, even if Neuralogix AI or a Neuralogix AI's authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                            </div>

                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Revisions and Errata</h3>
                                <p>The materials appearing on Neuralogix AI's web site could include technical, typographical, or photographic errors. Neuralogix AI does not warrant that any of the materials on its web site are accurate, complete, or current. Neuralogix AI may make changes to the materials contained on its web site at any time without notice. Neuralogix AI does not, however, make any commitment to update the materials.</p>
                            </div>

                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Links</h3>
                                <p>Neuralogix AI has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Neuralogix AI of the site. Use of any such linked web site is at the user's own risk.</p>
                            </div>

                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Site Terms of Use Modifications</h3>
                                <p>Neuralogix AI may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>
                            </div>

                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Governing Law</h3>
                                <p>Any claim relating to Neuralogix AI's web site shall be governed by the laws of the State of New Jersey without regard to its conflict of law provisions.</p>
                                <p>General Terms and Conditions applicable to Use of a Web Site.</p>
                            </div>

                            <div className="privacy-policy-content fade-in-element bottom">
                                <h3>Privacy Policy</h3>
                                <p>Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to understand how we collect, use, communicate and disclose and make use of personal information. The following outlines our privacy policy.</p>
                                <ul className="parvacy-sub-menu-content">
                                    <li>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.</li>
                                    <li>We will collect and use of personal information solely with the objective of fulfilling those purposes specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.</li>
                                    <li>We will only retain personal information as long as necessary for the fulfillment of those purposes.</li>
                                    <li>We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.</li>
                                    <li>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</li>
                                    <li>We will protect personal information by reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.</li>
                                </ul>
                            </div>                    
                        </div>
                    </div>
                </section>

        
            </main>    

        <Footer />
    </>
  )
}

export default PrivacyPolicy
